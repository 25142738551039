<template>
  <div class="practice-page-four-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>
    <div class="finger" @click="handleClickCamera()">
      <img src="@/assets/img/17-Tutorials/instructions.svg" alt="" />
    </div>
    <div class="practice-page-four-content">
      <SceneSwitchThumbnails
        :buttons="buttonList"
        :numBers="numBers"
        @changeTheNumbers="changeTheNumbers"
      >
      </SceneSwitchThumbnails>
      <template v-for="(item, index) in imgList">
        <div
          :key="index"
          :class="{
            imgLeft: item.position === 0,
            imgRight: item.position === 1,
          }"
          v-if="index === numBers - 1"
        >
          <img :src="item.hasClicked ? item.answerImg : item.bgImg" alt="" />
        </div>
      </template>

      <div class="customlesson-conter-right-text">
        <div class="right-text">
          <span class="pinyin font-pinyin-medium">{{ titleInfo.pinyin }}</span>
          <span class="font-hanzi-medium">{{ titleInfo.hanzi }}</span>
        </div>
        <div class="camera">
          <img src="@/assets/img/16-GAMES/G04-photo-studio/camera.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import SceneSwitchThumbnails from "@/components/Course/CoursePage/StorySwitcher/sceneSwitchThumbnails.vue";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
export default {
  name: "PhotoGame",
  components: {
    SceneSwitchThumbnails,
    PageButton,
  },
  props: {
    imgList: {
      type: Array,
      require: true,
    },
    buttonList: {
      type: Array,
      require: true,
    },
    titleInfo: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      numBers: 1, //start with the finder
      isLastStep: false,

      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "speak",
            startNumberRange: 1,
            endNumberRange: 100,
          },
        ],
      },
      clickSocketInfo: {},
      clickAsideSocketInfo: {},
    };
  },
  // 发送
  watch: {
    clickSocketInfo: {
      handler(value) {
        const data = {
          clickType: 30005,
          data: { value },
          text: "practicePage页面按钮socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
    },
    clickAsideSocketInfo: {
      handler(value) {
        const data = {
          clickType: 3000501,
          data: { value },
          text: "practicePage页面按钮socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
    },
    numBers: {
      handler(value) {
        if (value % 2 == 0) {
          this.nextStop = true;
          console.log(value);
        }
      },
    },
  },
  // 接收
  mounted() {
    this.$bus.$on("clickPractFourPage", () => {
      this.handleClickCamera("socket", true);
    });
    this.$bus.$on("clickPractFourPageMenu", ({ index }) => {
      this.changeTheNumbers(index, true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("clickPractFourPage");
    this.$bus.$off("clickPractFourPageMenu");
  },
  methods: {
    changeTheNumbers(index, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickAsideSocketInfo = {
          index,
          value: Math.random(),
        };
      }
      this.numBers = index;
    },
    handleClickCamera(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickSocketInfo = {
          value: Math.random(),
        };
      }
      playCorrectSound(true, false);
      this.$emit("changeTheBgImg", this.numBers);

      if (
        this.numBers === this.imgList.length &&
        this.imgList[this.numBers - 1].hasClicked
      ) {
        this.isLastStep = true;
        playCorrectSound();
        startConfetti();
      }
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
  },
};
</script>
<style scoped lang="scss">
.practice-page-four-container {
  .practice-page-four-content {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    background-color: #f7f4e0;
    border-radius: 58px;
    position: relative;
    .imgLeft {
      pointer-events: none;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      img {
        width: 70%;
      }
      .customlesson-picture-text {
        position: absolute;
        top: 77%;
        left: 50%;
        z-index: 12;
        transform: rotate(-7deg);
        span {
          color: #cd4c3f;
          font-size: 50px;
          font-family: "GenSenRoundedTW";
          display: inline-block;
        }
      }
    }
    .imgRight {
      width: 100%;
      position: absolute;
      top: 0;
      left: 40%;
      img {
        width: 60%;
      }
      .customlesson-picture-text {
        position: absolute;
        top: 79%;
        left: 10%;
        z-index: 13;
        transform: rotate(7deg);
        span {
          color: #fff;
          font-size: 50px;
          font-family: "GenSenRoundedTW";
          display: inline-block;
        }
      }
      .user-avatar {
        position: absolute;
        top: 38%;
        width: 14%;
        height: 26%;
        left: 11%;
        border-radius: 50%;
      }
    }
    .customlesson-conter-right-text {
      width: 98%;
      height: 95%;
      .right-text {
        background-color: #cd4c3f;
        border-top-right-radius: 29px;
        border-bottom-right-radius: 29px;
        position: absolute;
        top: 6%;
        left: 0;
        display: flex;
        justify-content: center;
        flex-flow: column;
        z-index: 9;
        width: auto;
        height: auto;
        padding: 3%;

        span:nth-child(1) {
          color: #ffffff;
          font-size: 35px;
        }
        span:nth-child(2) {
          color: #ffffff;
          font-size: 50px;
          font-family: "GenSenRoundedTW";
        }
      }
      .camera {
        position: absolute;
        bottom: 0;
        left: 10%;
        width: 25%;
        img {
          width: 100%;
          // cursor: pointer;
        }
      }
    }
  }
  .finger {
    position: absolute;
    bottom: 0;
    left: 22%;
    z-index: 10;
    width: 45%;
    cursor: pointer;
    img {
      width: 80%;
    }
  }
}
</style>
